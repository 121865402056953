.title-sidebar {
  background-color: $color-primary;
  display: flex;
  flex-direction: column;
  transform-origin: 0 50%;

  h1 {
    writing-mode: vertical-rl;
    text-orientation: upright;
    color: $color-text-light;
    font-size: 4rem;
    margin: 1.5rem 1.5rem 4rem 0;
  }

  &--open {
    animation: show 0.3s ease forwards;
  }
  &--closed {
    animation: hide 0.3s ease forwards;
  }
}

@keyframes hide {
  0% {
    width: 2.5rem;
  }
  100% {
    width: 0;
  }
}

@keyframes show {
  0% {
    width: 0;
  }
  100% {
    width: 2.5rem;
  }
}
