// COLOR VARIABLES
// define scale colour palette.
$color-primary: rgb(var(--arc-red-060));
$color-accent: rgb(var(--arc-blue-030));

$color-text-light: rgb(var(--arc-white-000));
$color-text-normal: rgb(var(--scale-color-text-normal));
$color-text-disabled: rgb(var(--scale-color-text-disabled));
$color-text-link: $color-primary;

$color-background: rgb(var(--scale-background-color));
$color-background-dark: rgb(var(--scale-background-dark));
$color-background-light: rgb(var(--arc-container-color));
$color-background-instructions: rgb(var(--scale-background-instructions));
$color-line: rgb(var(--arc-grey-020));
$color-select-arrow: rgb(var(--arc-grey-100));

$color-white: rgb(var(--arc-white-000));

$color-layer-item-state: rgb(var(--scale-layer-item-state-color));
$color-layer-checkbox: rgb(var(--arc-grey-050));

$color-layer-list-open: rgb(var(--arc-grey-050));

$color-reading-map-button: rgb(var(--arc-grey-070));

$color-apply-text: $color-layer-checkbox;

$color-loading-text: rgb(var(--arc-grey-060));

$color-zoom-button-hover: $color-background-instructions;

$color-transparent-background: rgba(var(--arc-white-000), 0.9);

// Media Query Variables
// from Angular Layout CDK

$screen-x-small: 600px;
$screen-small: 1000px;
$screen-medium: 1280px;
$screen-large: 1921px;
