.legend-item-container {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  margin: 0.25rem 0;
  .legend-layer-swatch {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 24px;
  }

  .scenario-legend-swatch {
    align-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 0.25rem;
    .scenario-legend-icon {
      width: 1.5rem;
      height: 1.5rem;
      clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    }
  }

  .legend-label-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: $color-layer-item-state;
  }
}
