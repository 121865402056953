mgt-login {
  --btn-color: var(--arc-color-primary);

  --login-signed-out-button-background: transparent;
  --login-signed-out-button-hover-background: transparent;
  --login-signed-out-button-text-color: rgb(var(--arc-font-color));
  --login-signed-in-background: transparent;
  --login-signed-in-hover-background: transparent;
  --login-button-padding: 0px;
  --login-popup-background-color: rgb(var(--arc-background-color));
  --login-popup-text-color: rgb(var(--arc-font-color));
  --login-popup-command-button-background-color: rgb(var(--arc-color-primary));
  --login-popup-padding: var(--arc-spacing-small);
  --login-add-account-button-text-color: rgb(var(--arc-font-color));
  --login-add-account-button-background-color: transparent;
  --login-add-account-button-hover-background-color: transparent;
  --login-command-button-background-color: rgb(var(--arc-color-primary));
  --login-command-button-hover-background-color: rgb(var(--arc-color-primary));
  --login-command-button-text-color: rgb(var(--arc-white-000));
  --person-line1-text-color: rgb(var(--arc-font-color));
  --person-line1-font-size: var(--arc-font-size-small);
  --person-line2-text-color: rgb(var(--arc-font-color), 0.8);
  --person-line2-font-size: var(--arc-font-size-x-small);
  --person-background-color: transparent;
  --person-avatar-size: var(--arc-spacing-large);
  --stroke-width: 0px;
  --layer-corner-radius: 3;
}
