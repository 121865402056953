.item-container {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  margin: 0.25rem 0;
  .layer-swatch {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 24px;
  }
  .item-col {
    display: flex;
    flex-direction: column;
    flex: 1;

    .checkbox-row {
      color: $color-layer-item-state;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .scenario-layer-swatch {
    align-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 0.25rem;
    .scenario-icon {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 25px;
    }
  }

  .label-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: $color-layer-item-state;
  }

  .checkbox-col {
    color: $color-layer-item-state;
    display: flex;
    align-items: flex-end;
  }

  .mat-checkbox-label {
    font-size: 10px;
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $color-layer-checkbox !important;
  }
}
