.constraint-control-container {
  margin-bottom: 1rem;
  padding: 0 1rem;
  .header {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  mat-form-field {
    width: 100%;
  }
  .mat-icon-button {
    height: 1rem !important;
    width: 1rem !important;
  }
}
