.line-container {
  padding: 0 1.8rem;
}

.line-container-full-width {
  padding: 0;
}

.line {
  width: 100%;
  height: 0.0625rem;
  background-color: $color-line;
}
