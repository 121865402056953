.left-panel-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.125rem;
  background-color: $color-background-light;
  h1 {
    margin-top: 0;
    font-size: 3rem;
  }
  .logo {
    max-height: 2rem;
    max-width: 7.35rem;
  }

  scale-seperator {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .text-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  arc-button {
    width: 116px;
  }
}

.mat-drawer {
  color: rgb(var(--arc-font-color)) !important;
}
.mat-container {
  color: rgb(var(--arc-font-color)) !important;
}
