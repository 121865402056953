.controls-container {
  padding: 2rem 1rem 0 1rem;

  .control-elements {
    arc-button {
      height: 40px;
      width: 127px;
    }

    .toggle {
      width: 100%;
      margin-top: 2rem;
      &__group {
        width: 100%;
        border: unset;
        height: 1.75rem;
      }

      &__button {
        border: 1px solid $color-primary;
        width: 50%;
        height: 100%;
        button {
          height: 100%;
          .mat-button-toggle-label-content {
            line-height: 1.25rem;
          }
        }
        &:first-child {
          border-radius: 6.25rem 0 0 6.25rem;
        }

        &:last-child {
          border-radius: 0 6.25rem 6.25rem 0;
        }
      }

      .mat-button-toggle-checked {
        background-color: $color-primary;
        color: rgb(var(--arc-white-000));
        border: 1px solid $color-primary;
      }
    }

    @include lg {
      h2 {
        font-size: var(--arc-font-size-x-large);
      }
    }
  }
  .button-container {
    display: flex;
    gap: 0.5rem;
  }
  .apply-button-container {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    arc-button {
      height: 40px;
      width: 85px;
    }
    p {
      margin: 0;
      color: $color-apply-text;
    }
  }
}
