.toggle-sidebar-button {
  align-self: flex-end;
  color: $color-primary !important;
  min-width: 2.5rem !important;
  height: 6rem;
  width: 2.5rem;
  padding: 0 !important;
  z-index: 10;
  transition: all 0.3s ease;
  position: absolute !important;
  top: 50%;
  border-radius: 0px !important;

  &--open {
    transform: translateX(29.49rem) rotate(180deg);
    background-color: $color-primary !important;
    color: $color-text-light !important;
  }

  &--closed {
    transform: translateX(0);
    background-color: $color-white !important;
  }
}
