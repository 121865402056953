.cost-legend-container {
  display: flex;
  flex-direction: column;
  .cost-legend-item {
    width: 100%;
    height: 10px;
  }
  .cost-legend-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $color-layer-item-state;
  }
}
