/* ARC BASE STYLES */
@import '~@arc-web/components/dist/themes/index.css';
@import '~@arc-web/components/dist/themes/light.css';
@import '~@arc-web/components/dist/themes/dark.css';

/* ABSTRACT STYLES */
@import './abstracts/mixins';
@import './abstracts/variables';

/* COMPONENT STYLES */
@import './components/arc-button';
@import './components/arc-navbar';
@import './components/constraint-control';
@import './components/controls-container';
@import './components/control-panel';
@import './components/cost-legend-item';
@import './components/instructions';
@import './components/layer-list';
@import './components/layer-list-item';
@import './components/left-panel';
@import './components/legend-item';
@import './components/loading-spinner';
@import './components/mat-select';
@import './components/mgt-login';
@import './components/mgt-person';
@import './components/scenario-select';
@import './components/seperator';
@import './components/summary';
@import './components/title-sidebar';
@import './components/toggle-sidebar-button';
@import './components/zoom-controls';

/* LAYOUT STYLES */
@import './layout/body';
@import './layout/main';

/* MATERIAL THEME */
@import './themes/material';

/* TYPOGRAPHY*/
@import './base/typography';
