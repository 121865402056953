.control-panel {
  &__close {
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__content {
    height: calc(100% - 40px);
    width: 100%;
    p {
      margin: 0;
      font-size: 1rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 1.5rem;
    h1 {
      align-self: flex-start;
      font-size: var(--arc-font-size-xxx-large);
      margin: 0;

      @include lg {
        font-size: var(--arc-font-size-xx-large);
      }
    }
  }

  &__constraints {
    gap: 1.5rem;
    margin-top: 1.5rem;
  }

  &__row {
    display: grid;
    gap: var(--arc-spacing-normal);
    grid-template-columns: 27rem repeat(3, 1fr);
    align-items: center;
    margin-bottom: var(--arc-spacing-normal);

    @include selectionButton;
    @include md {
      grid-template-columns: 20rem repeat(3, 1fr);
    }

    &--LCOE {
      grid-template-columns: 27rem repeat(4, 1fr);
      align-items: stretch;

      @include md {
        grid-template-columns: 20rem repeat(4, 1fr);
      }

      .mat-button-wrapper {
        display: flex;
        flex-direction: column;
        line-height: var(--arc-line-height-dense);

        .description {
          font-size: var(--arc-font-size-x-small);
          white-space: pre-wrap !important;
        }
      }
    }
  }
  &__title-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;

    @include md {
      font-size: var(--arc-font-size-x-large);
    }
  }

  &__full-width {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__apply-btn-row {
    display: flex;
    justify-content: flex-end;
    margin: 1.5rem 0;

    @include md {
      margin: 0;
    }

    arc-button {
      height: 2.5rem;
    }
  }
}

.control-panel-dialog {
  height: 50rem;
  max-height: 800px;
  width: 87.5rem;

  @include md {
    max-width: 90vw !important;
    max-height: 95vh !important;
  }
}
