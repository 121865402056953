.zoom-controls {
  z-index: 1;
  position: absolute;
  margin: 0.625rem 0.625rem 0 0;
  float: right;
  right: 1rem;
  padding: 1rem;

  .map-button {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    background-color: $color-background !important;
    box-shadow: 0px 2px 3px rgba(118, 118, 118, 0.2);
    &__reset {
      margin-bottom: 0.5rem;
      border-radius: 0 !important;
      background-color: $color-background !important;
      box-shadow: 0px 2px 3px rgba(118, 118, 118, 0.2);
    }
  }
}
.map-button:hover {
  background-color: $color-zoom-button-hover;
}
.map-button__reset:hover {
  background-color: $color-zoom-button-hover;
}
