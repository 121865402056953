@mixin formatFormField {
  background-color: $color-background-dark;
  padding: 0 !important;

  .mat-form-field {
    min-width: 0 !important;
    width: 100%;
    height: 100% !important;
  }

  .mat-form-field-wrapper {
    height: 100% !important;

    .mat-form-field-flex {
      height: 100%;

      .mat-form-field-infix {
        padding: 0.75rem;
        border-top: 0;
        height: 100%;
        width: 100% !important;
      }
    }

    .mat-form-field-underline {
      bottom: 0 !important;
      height: 0 !important;
    }
  }
}

@mixin selectionButton {
  button {
    border-radius: 0;
    padding: var(--arc-spacing-normal);
    background-color: $color-background-dark !important;
    .description {
      font-weight: var(--arc-font-weight-normal);
    }
  }
  .selected,
  button:hover,
  button:focus {
    background-color: $color-accent !important;
  }
}

// media query mixins
// exta small devices
@mixin xs {
  @media (max-width: #{$screen-x-small}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-small}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-medium}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-large}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-large}) {
    @content;
  }
}
