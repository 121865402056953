.menu {
  display: flex;
  position: absolute;
  padding: 1rem;
  z-index: 1;
  &--open {
    width: 280px;
  }
  &--closed {
    width: 0px;
  }
  .toggle {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    box-shadow: 0px 2px 3px rgba(118, 118, 118, 0.2);
    &--open {
      background-color: $color-layer-list-open !important;
      mat-icon {
        color: $color-white;
      }
    }
    &--closed {
      background-color: $color-white !important;
      mat-icon {
        color: $color-layer-list-open;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: row;
    transform: scaleX(0);
    transform-origin: left;
    background-color: $color-background;
    margin-left: 0.5rem;

    .items-container {
      padding: 0.5rem;
      h1 {
        margin: 0;
        font-size: 1.125rem;
      }
      p {
        font-size: 0.75rem;
        margin: 0.5rem 0;
      }
      .item-type {
        margin: 0.5rem 0;
      }
    }
    &--open {
      transform: scaleX(1);
    }

    &--close {
      transform: scaleX(0);
    }
  }
  .items > a {
    display: flex;
    background-color: $color-white;
    height: 2.5rem;
    width: 2.5rem;
    justify-content: center;
    align-items: center;
  }

  arc-button {
    width: 100%;
    --btn-color: $color-reading-map-button !important;
    color: $color-reading-map-button !important;
    font-size: 0.625rem;
    font-weight: 400;
  }
}
