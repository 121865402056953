.instructions {
  &__close {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  &__stepper {
    height: calc(100% - 40px);
    width: 100%;

    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
    .mat-horizontal-stepper-wrapper {
      height: 100%;
    }
    .mat-horizontal-content-container {
      height: 100%;
    }
    .mat-horizontal-stepper-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
  }

  &__button-container {
    align-self: flex-end;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: var(--arc-spacing-normal);
    padding-top: var(--arc-spacing-normal);
    background-color: $color-background-instructions !important;

    @include xs {
      flex-wrap: wrap;
    }

    @include sm {
      padding-right: var(--arc-spacing-normal);
    }
  }

  &__container {
    padding: 0 1.5rem;
    background-color: $color-background-instructions;
    height: 90%;

    @include sm {
      padding: 0;
    }

    h1 {
      align-self: flex-start;
      font-size: var(--arc-font-size-xxx-large);
      margin: 0;

      @include md {
        font-size: var(--arc-font-size-xx-large);
      }
      @include sm {
        font-size: var(--arc-font-size-x-large);
      }
    }
  }

  &__static {
    display: flex;
    gap: var(--arc-spacing-large);

    @include sm {
      flex-direction: column-reverse;
      align-items: center;

      .static-text,
      p,
      h3 {
        font-size: var(--arc-font-size-small);
      }
    }

    @include xl {
      .static-text,
      p,
      h3 {
        font-size: var(--arc-font-size-large);
      }
    }

    h2 {
      font-size: var(--arc-font-size-x-large);

      @include sm {
        font-size: var(--arc-font-size-medium);
        text-align: center;
      }
    }

    img {
      max-width: 50%;
      max-height: 550px;

      @include sm {
        margin-top: var(--arc-spacing-medium);
        width: 120px;
      }
    }

    &--right {
      align-items: center;
    }
    &--left {
      flex-direction: row-reverse;

      @include sm {
        flex-direction: column-reverse;
        img {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  &__selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    justify-content: center;

    @include lg {
      img {
        width: 175px;
      }
    }

    @include sm {
      height: auto;
      img {
        width: 120px;
        margin-top: var(--arc-spacing-medium);
      }
      p,
      h3 {
        font-size: var(--arc-font-size-small);
        text-align: center;
      }
    }

    @include xl {
      p,
      h3 {
        font-size: var(--arc-font-size-large);
      }
    }

    h2 {
      margin: 0;
      text-align: center;

      @include md {
        margin-top: var(--arc-spacing-normal);
        font-size: var(--arc-font-size-large);
      }

      @include sm {
        font-size: var(--arc-font-size-medium);
      }
    }

    .selection-buttons {
      display: flex;
      gap: var(--arc-spacing-normal);
      justify-content: center;
      flex-wrap: wrap;

      @include selectionButton;

      button {
        width: 15rem;
        max-width: 15rem;
        @include xl {
          width: 18rem;
          font-size: var(--arc-font-size-medium);
        }

        @include sm {
          width: 100%;
          line-height: var(--arc-line-height-dense);
          white-space: pre-wrap;
        }
      }
    }
  }

  &__full-width {
    width: 100%;
    height: 100%;
  }
}

.instruction-dialog {
  height: 90vh;
  max-height: 800px !important;
  .mat-stepper-header-position-bottom .mat-horizontal-content-container {
    padding: 0;
  }
}

.mat-dialog-container {
  background-color: rgb(var(--scale-background-instructions));

  @include sm {
    padding-right: 0 !important;
  }
}
.mat-horizontal-content-container {
  background-color: $color-background-instructions !important;
}
