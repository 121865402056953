.scenario-control-container {
  margin-bottom: 1rem;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  mat-form-field {
    width: 100%;
    border-radius: 4px;
    border: 1px solid $color-line;
  }
}
