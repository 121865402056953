h2 {
  font-size: var(--arc-font-size-xx-large);
  margin: 0 0 1.125rem 0;

  @include sm {
    font-size: var(--arc-font-size-x-large);
  }
}

a {
  color: $color-text-link;
}
