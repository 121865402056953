.summary-container {
  width: 100%;
  height: calc(100% - 250px);
  padding: 1rem;
  margin-top: 1.5rem;
  overflow-y: auto;

  .text-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    h1,
    h2,
    p {
      margin: 0;
    }
    h2 {
      font-size: 1rem;
    }
    &__scenario-property {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      display: flex;
      line-height: var(--arc-line-height-dense);
      gap: 1rem;
      padding: 1rem 0;
      .property {
        flex: 0.33;
        font-size: var(--arc-font-size-medium);
        color: $color-text-normal;

        &--disabled {
          color: $color-text-disabled;
        }
      }
      .value {
        flex: 0.66;
        font-size: var(--arc-font-size-large);
        font-weight: var(--arc-font-weight-bold);
      }
    }
  }

  @include sm {
    max-width: none;
  }
}
